import { SyncWildbookAction } from '@/components/Shared/dynamicList/DynamicListTypes';
import { ConvertDateToDayString, ConvertUtcStringToDate, GenerateCorrectTime, GetDateDiffMinutes, newDate } from "@/infrastructure/functional/datetimehelper"
import { DynamicGrid, DynamicGridRow, DynamicGridRowParentChild } from "../dynamicList/DynamicListTypes"
import { DynamicFormBusinessCasesFishing } from "./DynamicFormBusinessCasesFishing"
import { DynamicDataSet } from "./DynamicFormData"
import { ComboBoxDataSource } from "./ComboBoxDataSource"
import { TierartRules } from "@/infrastructure/serviceworker-related/TierartZusatzService"
import { addOrReplaceRecord, getRecord, loadAllRecords, wildbookTable } from "@/infrastructure/database/asyncDb"
import { getWildBookEntriesWithDetail } from "@/infrastructure/serviceworker-related/DynamicGridHuntingService"
import { JagdRevierTitel, assignFormValues, deleteHuntingRecord, findLocalHuntingRecord, findLocalHuntingRecordOverFormId, saveHuntingRecord, saveZusatzDaten } from "@/infrastructure/serviceworker-related/LocalWildBookDataAccess"
import { DynamicListStore } from "../dynamicList/DynamicListStore"
import { resolveMainListResourceTitel, resolveSubListResourceTitel } from "../dynamicList/DynamicListBusinessCase"
import { HuntingRecord } from "@/components/fishing/business/HuntingRecord"
import { syncWildbook } from "@/infrastructure/serviceworker-related/SyncWildbook"
import { BusinessChanges } from '../dynamicList/Hooks/BusinessFormChanges';
import { GeoState, GeoStateAction, GeoView } from '../geo/hooks/ChangeGeoMap';
import { GeoStoreData } from '../geo/hooks/GeoStoreData';

export class DynamicFormBusinessCasesHunting {

    private fishing: DynamicFormBusinessCasesFishing = new DynamicFormBusinessCasesFishing()

    public async getRemoteFormOverwriteFormWithBusinessCase(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<any> {
        let success = false
        switch( reactiveData.viewId) {
            case "WildbuchEintragJagd":
                await this.getWildbuchEintraegeInitial(reactiveData, clientRouteName)
                success = this.getWildbuchEintraege(reactiveData, clientRouteName)     
                if ( this.isNew(clientRouteName))    
                    await TierartRules.handleZusatzInfo(reactiveData.viewId, reactiveData.view)  
                    await TierartRules.handlePflichtfelder(reactiveData.viewId, reactiveData.view)
                return success
            case "WildbuchEintragFallwild":
                await this.getWildbuchEintraegeInitial(reactiveData, clientRouteName)
                success = this.getWildbuchEintraege(reactiveData, clientRouteName)   
                if ( this.isNew(clientRouteName))            
                    await TierartRules.handleZusatzInfo(reactiveData.viewId,reactiveData.view)  
                    await TierartRules.handlePflichtfelder(reactiveData.viewId, reactiveData.view)
                return success
            case "WildbuchEintragBeobachtung":
                await this.getWildbuchEintraegeInitial(reactiveData, clientRouteName)
                success = this.getWildbuchEintraege(reactiveData, clientRouteName)
                if ( this.isNew(clientRouteName))      
                    //await TierartRules.handleZusatzInfo(reactiveData.viewId,reactiveData.view)  
                    await TierartRules.handlePflichtfelder(reactiveData.viewId, reactiveData.view)
                return success
            case "WildbuchEintragBeobachtungSpez":
                await this.getWildbuchEintraegeInitial(reactiveData, clientRouteName)
                success = this.getWildbuchEintraege(reactiveData, clientRouteName)
                if ( this.isNew(clientRouteName))                        
                    await TierartRules.handlePflichtfelder(reactiveData.viewId, reactiveData.view)
                return success          
               }
    }

    public async deleteDynamicGridRow(reactiveData: DynamicDataSet, clientRouteName: string, rowId: string) : Promise<DynamicGridRowParentChild | undefined> {
        

        const gridRowTemp = DynamicListStore.GetSelectedGridRow()
        const gridRowStore = await getRecord(wildbookTable, gridRowTemp?.id ?? "") 
        const gridRow = gridRowStore.row as unknown as DynamicGridRow

        const parentDetail: DynamicGridRowParentChild = {
            parentRow: {
                parentRowId: undefined,
                rowId: undefined,
                isSynced: undefined,
                id: undefined,
                parentId: undefined,
                cell: {
                    image: {
                        imagePath: undefined,
                        imageState: undefined,
                        imageStateText: undefined,
                        imageType: undefined
                    },
                    content: {
                        titleResourceId: undefined,
                        contentResourceId: undefined,
                        title: undefined,
                        content: undefined
                    },
                    additional: {
                        textResourceId: undefined,
                        text: undefined,
                        gueltigVon: undefined,
                        gueltigBis: undefined
                    },
                    readableIdentification: undefined
                },
                syncState: undefined,
                detailForm: undefined,
                jsonDetail: undefined,
                rows: undefined,
                editable: undefined,
                sort: undefined,
                activeData: undefined,
                appSynchronId: undefined,
                localDirty: undefined,
                isVisible: undefined
            },
            childRow: undefined
        } 

        if ( gridRow  && gridRow.syncState) {

            gridRow.cell.image.imageType = "notsynced"
            gridRow.cell.image.imagePath = "img/icons/notsynced.svg"
            gridRow.isSynced = false
            if ( gridRow.syncState) {
                gridRow.syncState.isSynched = false
                gridRow.syncState.stateResourceId = "Sync.State.Delete"
            }

            if ( gridRow.cell.image) {
                gridRow.cell.image.imageType = "notsynced"
                gridRow.cell.image.imagePath = "img/icons/notsynced.svg"
                gridRow.cell.image.imageState = "notsynced"
            }

            //gridRow.cell.content.title = await resolveMainListResourceTitel("wildBookEntriesWithDetail", gridRow.cell.content.titleResourceId ?? "", gridRow )
            
            await saveHuntingRecord(gridRow)

            
            const listNotify = BusinessChanges.getService()
            listNotify.onRowChanged({action: "delete", row: gridRow })
            listNotify.reset()

            const response = await syncWildbook(gridRow, "delete")  
            if ( response && response.success && response.parentRow) {                
                if (!response.offline) {   
                    if ( response.parentRow.syncState)                 
                        response.parentRow.syncState.stateResourceId = "Sync.State.Hidden"
                    await saveHuntingRecord(response.parentRow)
                    listNotify.onRowChanged({action: "hided", row: response.parentRow })
                    listNotify.reset()
                }

                parentDetail.parentRow = response.parentRow
                return parentDetail
            }

        }
        return undefined
    }

    public async saveRemoteFormOverwriteFormWithBusinessCase(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<DynamicGridRowParentChild | undefined> {
        let isUpdate = false
        if ( 
            clientRouteName === "WildBookFallMap" ||
            clientRouteName === "WildBookEntryMap" ||
            clientRouteName === "WildBookObserveMap" ||
            clientRouteName === "WildBookObserveSpecMap" 
            ) {
            isUpdate = true
        }

        let gridRow: DynamicGridRow | undefined = undefined
        if ( isUpdate) {
            const gridRowTemp = DynamicListStore.GetSelectedGridRow()
            const gridRowStore = await getRecord(wildbookTable, gridRowTemp?.id ?? "") 
            gridRow = gridRowStore.row as unknown as DynamicGridRow
        }
        

        switch( reactiveData.viewId) {           
            case "WildbuchEintragJagd":
                return  await saveWildBook("99.1" ,  reactiveData.viewId, gridRow, reactiveData, isUpdate)
            case "WildbuchEintragFallwild":
                return  await saveWildBook("99.2" ,  reactiveData.viewId, gridRow, reactiveData, isUpdate)                
            case "WildbuchEintragBeobachtung":
                return  await saveWildBook("99.3" ,  reactiveData.viewId, gridRow, reactiveData, isUpdate)
            case "WildbuchEintragBeobachtungSpez":
                return  await saveWildBook("99.4" ,  reactiveData.viewId, gridRow, reactiveData, isUpdate)
               
           }

           
      return             
    }

    private async getWildbuchEintraegeInitial(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<boolean> {

        const gueltigDauerInMinuten = await this.getGueltigikeitsDauer(reactiveData.viewId, true)
        let isEditPossible = false

        
        const geoService = GeoView.getService()
       
        if ( ! this.isNew(clientRouteName) ) {

            const gridRowTemp = DynamicListStore.GetSelectedGridRow()
            const gridRow = gridRowTemp as unknown as DynamicGridRow
           
          

            reactiveData.view.forEach(row => {
                if ( row.formularFeldname === "Gewicht") {
                    if (row.value && row.value.length > 0) {
                        row.value =  row.value.replace(",", ".")
                    }
                }
                if ( row.formularFeldname === "DatumZeitErzeugung") {                    
                    const dateValue = GenerateCorrectTime( ConvertUtcStringToDate(row.value) )
                    const dt = newDate()
                    let minutes = GetDateDiffMinutes(dt,dateValue)
                    if ( dateValue.getTime() > dt.getTime()) minutes = 0
                    if ( minutes <= gueltigDauerInMinuten) isEditPossible = true
                    
                }
            })

            

            if (gridRow && (!gridRow.appSynchronId || gridRow.appSynchronId.length < 1 )) {
                isEditPossible = false
            }


            const geoState: GeoState = {
                action: undefined,
                wgs84X: undefined,
                wgs84Y: undefined,
                revierNumber: undefined,
                typ: undefined,
                lastState: undefined,
                isEditable: undefined
            }

            if ( isEditPossible) {
                GeoStoreData.enableEditMode()
               
            } else {
                GeoStoreData.disableEditMode()
            }

            let i = 1
            for( const row of reactiveData.view) {
                if (row.formularFeldname && row.formularFeldname.length > 0 && row.formularFeldname.indexOf("Tierart") > 0) {
                    if ( i > 1) row.istEditierbar = false
                    i++
                }
            }

            if (!isEditPossible) {
                for( const row of reactiveData.view) {
                    if ( row.feldTyp === "Titel" || row.feldTyp === "SubTitel" || row.feldTyp === "Space") continue
                    if ( row.feldTyp === "Karte" || row.feldTyp === "Filter" || row.feldTyp === "ResetFilter" ) continue
                    
                    if (row.feldTyp === "ActionButton") {
                        row.istSichtbar = false
                    } else {
                        row.istEditierbar = false
                    }
                }
                return true
            }     
        }

       

        if ( clientRouteName === "Hunting" || clientRouteName === "WildBookEntry"
        || clientRouteName === "WildBookFall"
        || clientRouteName === "WildBookObserve"
        || clientRouteName === "WildBookObserveSpec"
        ) 
            {
                for( const row of reactiveData.view) {
                    if (row.formularFeldname === "RuleDynamicSave") {               
                        row.istSichtbar = true
                    } 
                    if (row.formularFeldname === "RuleDynamicDelete") {               
                        row.istSichtbar = false
                    }  
                    if (row.formularFeldname === "RuleDynamicShouldDelete") {               
                        row.istSichtbar = false
                    }            
                    if (row.formularFeldname === "RuleDynamicChange") {               
                        row.istSichtbar = false
                    }

                    if ( row.formularFeldname.indexOf("Revier") >= 0 ) {
                        row.istEditierbar = false
                    }

                    if ( row.feldTyp === "Titel" || row.feldTyp === "SubTitel" || row.feldTyp === "Space" || row.feldTyp === "ActionButton") continue
                    if ( row.feldTyp === "Karte" || row.feldTyp === "Filter" || row.feldTyp === "ResetFilter" ) continue

                    if (  row.formularFeldname && ( row.formularFeldname.indexOf("Anzeige") >= 0 || row.formularFeldname.indexOf("anzeige") >= 0)) { 
                        row.istEditierbar = false
                    } else {
                        row.istEditierbar = true
                    }

                    if ( row.formularFeldname.indexOf("Revier") >= 0 && row.teilFormularName.indexOf("WildbuchEintragOrtJagd") >= 0) {
                        row.istEditierbar = false
                    }


                }

                let i = 1
                for( const row of reactiveData.view) {
                    if (row.formularFeldname && row.formularFeldname.length > 0 && row.formularFeldname.indexOf("Tierart") > 0) {
                        if ( i > 1) row.istEditierbar = false
                        i++
                    }
                }

                return true
            }

            // if ( !isEditPossible) {
            //     for( const row of reactiveData.view) {
            //         if (row.formularFeldname === "RuleDynamicSave") {               
            //             row.istSichtbar = false
            //         } 
            //         if (row.formularFeldname === "RuleDynamicDelete") {               
            //             row.istSichtbar = false
            //         }  
            //         if (row.formularFeldname === "RuleDynamicShouldDelete") {               
            //             row.istSichtbar = false
            //         }            
            //         if (row.formularFeldname === "RuleDynamicChange") {               
            //             row.istSichtbar = false
            //         }            
            //         if ( row.feldTyp === "Titel" || row.feldTyp === "SubTitel" || row.feldTyp === "Space" || row.feldTyp === "ActionButton") continue
            //         if ( row.feldTyp === "Karte" || row.feldTyp === "Filter" || row.feldTyp === "ResetFilter" ) continue
            //         row.istEditierbar = false
            //     }
            // }

        let i = 1
        for( const row of reactiveData.view) {
            if (row.formularFeldname && row.formularFeldname.length > 0 && row.formularFeldname.indexOf("Tierart") > 0) {
                if ( i > 1) row.istEditierbar = false
                i++
            }
        }
        return true
    }


    private getWildbuchEintraege(reactiveData: DynamicDataSet, clientRouteName: string) : boolean {
        if ( clientRouteName.indexOf("WildbuchEintrag") < 0 ) 
            return true
    
        reactiveData.view.forEach(row => {
            if (row.formularFeldname === "JagdRevier") {               
                row.istSichtbar = true               
            }

        })
        return true
    }

    private isNew(clientRouteName: string) : boolean {        
        if ( clientRouteName === "WildBookFallMap") return false
        if ( clientRouteName === "WildBookObserveMap" ) return false
        if ( clientRouteName === "WildBookObserveSpecMap" ) return false
        if ( clientRouteName === "WildBookEntryMap" ) return false

        
        if ( clientRouteName.indexOf("WildBook") >= 0 ) return true
        return false
    }

    private async getGueltigikeitsDauer(viewId: string, isUpdate: boolean) : Promise<number> {
        let gueltigDauerInMinuten = 24*60

        if ( !isUpdate) {
            if ( viewId === "WildbuchEintragLokalisierungJagd") {
                gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdJagdAbgangBeobachtungEingabe") * 60
            }
            if ( viewId === "WildbuchEintragLokalisierungFallwild") {
                gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdJagdAbgangBeobachtungEingabe") * 60
            }
            if ( viewId === "WildbuchEintragLokalisierungBeobachtungSpez") {
                gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdJagdAbgangBeobachtungSpezEingabe") * 60
            }
            if ( viewId === "WildbuchEintragLokalisierungBeobachtung") {
                gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdJagdAbgangBeobachtungSpezEingabe") * 60
            }
        } else {
            if ( viewId === "WildbuchEintragLokalisierungJagd") {
                gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdJagdAbgangBeobachtungAenderung") * 60
            }
            if ( viewId === "WildbuchEintragLokalisierungFallwild") {
                gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdJagdAbgangBeobachtungAenderung") * 60
            }
            if ( viewId === "WildbuchEintragLokalisierungBeobachtungSpez") {
                gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdJagdAbgangBeobachtungSpezAenderung") * 60
            }       
            if ( viewId === "WildbuchEintragLokalisierungBeobachtung") {
                gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdJagdAbgangBeobachtungSpezAenderung") * 60
            }        
        }
        if ( gueltigDauerInMinuten === 0) gueltigDauerInMinuten = 24*60

        return gueltigDauerInMinuten
    }

}

async function saveWildBook(kategorie: string, formName: string,  gridRow: DynamicGridRow | undefined, reactiveData: DynamicDataSet, isUpdate: boolean) : Promise<DynamicGridRowParentChild | undefined> {
    
    const parentDetail: DynamicGridRowParentChild = {
        parentRow: {
            parentRowId: undefined,
            rowId: undefined,
            isSynced: undefined,
            id: undefined,
            parentId: undefined,
            cell: {
                image: {
                    imagePath: undefined,
                    imageState: undefined,
                    imageStateText: undefined,
                    imageType: undefined
                },
                content: {
                    titleResourceId: undefined,
                    contentResourceId: undefined,
                    title: undefined,
                    content: undefined
                },
                additional: {
                    textResourceId: undefined,
                    text: undefined,
                    gueltigVon: undefined,
                    gueltigBis: undefined
                },
                readableIdentification: undefined
            },
            syncState: undefined,
            detailForm: undefined,
            jsonDetail: undefined,
            rows: undefined,
            editable: undefined,
            sort: undefined,
            activeData: undefined,
            appSynchronId: undefined,
            localDirty: undefined,
            isVisible: undefined
        },
        childRow: undefined
    } 
    
    if ( isUpdate && gridRow ) {        
        if ( gridRow.detailForm )
            assignFormValues( gridRow.detailForm, reactiveData )
        parentDetail.parentRow = gridRow 
        await addOrReplaceRecord(wildbookTable, {id: gridRow?.id, row: gridRow})

        if ( gridRow) {    
            gridRow.cell.content.title =  await resolveMainListResourceTitel("wildBookEntriesWithDetail", await JagdRevierTitel.getTitelFromForm(gridRow.detailForm) ?? "" , gridRow )
            gridRow.cell.image.imageType = "notsynced"
            gridRow.cell.image.imagePath = "img/icons/notsynced.svg"
            gridRow.isSynced = false
            if ( gridRow.syncState) {
                gridRow.syncState.stateResourceId = "Sync.State.Change"
                gridRow.syncState.isSynched = false
            }
            await saveHuntingRecord(gridRow)
            
            const zusatzRecords = await saveZusatzDaten(kategorie, gridRow?.id, gridRow.detailForm?.view)
            gridRow.zusatzTierartRecords = zusatzRecords
    
            
            const response = await syncWildbook(gridRow, "change") 
            const listNotify = BusinessChanges.getService()
            listNotify.onRowChanged({action: "change", row: gridRow })
            listNotify.reset()
            if ( response && response.success && response.parentRow) {
                await saveHuntingRecord(response.parentRow)                
                listNotify.onRowChanged({action: "change", row: response.parentRow })
                listNotify.reset()
            }
        }            

        return parentDetail
    }

        const newGridRow = await HuntingRecord.CreateJagdAbgangRow(kategorie)
        if ( newGridRow.detailForm)
            await assignFormValues(newGridRow.detailForm, reactiveData)
        
        newGridRow.cell.content.contentResourceId = await JagdRevierTitel.getTitelFromForm(newGridRow.detailForm) ?? ""

        if ( newGridRow.cell.readableIdentification ) {
            newGridRow.cell.readableIdentification.readableResourceId = JagdRevierTitel.readableResourceId 
            newGridRow.cell.readableIdentification.readableName = JagdRevierTitel.readableName
            newGridRow.cell.readableIdentification.readableNumber = JagdRevierTitel.readableNumber
        }

        newGridRow.cell.content.title = await resolveMainListResourceTitel("wildBookEntriesWithDetail", newGridRow.cell.content.contentResourceId  , newGridRow )        
        parentDetail.parentRow = newGridRow

        newGridRow.cell.image.imageType = "notsynced"
        newGridRow.cell.image.imagePath = "img/icons/notsynced.svg"
        newGridRow.isSynced = false
        if ( newGridRow.syncState) {
            newGridRow.syncState.stateResourceId = "Sync.State.NotSynced"
            newGridRow.syncState.isSynched = false
        }

        await addOrReplaceRecord(wildbookTable, {id: newGridRow?.id, row: newGridRow})
        const zusatzRecords = await saveZusatzDaten(kategorie, newGridRow?.id, newGridRow.detailForm?.view)
        newGridRow.zusatzTierartRecords = zusatzRecords

        const listNotify = BusinessChanges.getService()
        listNotify.onRowChanged({action: "change", row: gridRow })
        listNotify.reset()

        const response = await syncWildbook(newGridRow, "new") 
        if ( response && response.success && response.parentRow) {
            await saveHuntingRecord(response.parentRow)
            parentDetail.parentRow = response.parentRow

            if ( !response.offline) {
                const listNotify = BusinessChanges.getService()
                listNotify.onRowChanged({action: "change", row: response.parentRow })
                listNotify.reset()
            }


            return parentDetail
        }

    return undefined
}



