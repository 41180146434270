import { RuleLoadFishing } from "@/business/application/commands/Concrete/RuleLoadFishing";
import { DynamicDataSet } from "./DynamicFormData";
import { FishingEdit } from "@/components/fishing/business/FishingEdit";
import { ConvertDateToDayString, ConvertDateToIsoString, ConvertUtcStringToDate, GenerateCorrectTime, GetDateDiffMinutes, makeValidUtcString, newDate } from "@/infrastructure/functional/datetimehelper";
import { findLocalFishingDetailRecord, findLocalFishingDetailRecordById, findLocalFishingParentRecord } from "@/components/fishing/business/FishingAdd";
import { FishingRecordDetail } from "@/components/fishing/business/FishingRecordDetail";
import { addOrReplaceRecord, fishbookTable, loadAllRecords } from "@/infrastructure/database/asyncDb";
import { ComboBoxDataSource } from "./ComboBoxDataSource";
import { syncFishbook } from "@/infrastructure/serviceworker-related/SyncFishbook";
import { resolveMainListResourceTitel, resolveSubListResourceTitel } from "../dynamicList/DynamicListBusinessCase";
import { ObjectSearchDataSource } from "./ObjectSearchDataSource";
import { ObjectListItems } from "@/infrastructure/generics/namedListItem";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import { Resource } from "@/infrastructure/resource/resource";
import { DynamicGridRow, DynamicGridRowParentChild, SyncStateResponse } from "../dynamicList/DynamicListTypes";
import { DynamicListStore } from "../dynamicList/DynamicListStore";
import { DynamicDetailListStore } from "../dynamicList/DynamicDetailListStore";

export class DynamicFormBusinessCasesFishing {

public async getRemoteFormOverwriteFormWithBusinessCase(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<any> {
   switch( reactiveData.viewId) {
    case "HomeFischerei":
        await ObjectSearchDataSource.getData("FischereiRevier","", "", "") as unknown as ObjectListItems[]
        return await getHomeFischerei(reactiveData)

    case "FischbuchEintragPachtrevier":
        await getFischbuchEintraegeInitial(reactiveData, clientRouteName)
        return await getFischbuchEintraege(reactiveData, clientRouteName)

    case "FischbuchEintragPatentrevier":
        await getFischbuchEintraegeInitial(reactiveData, clientRouteName)
        return await getFischbuchEintraege(reactiveData, clientRouteName)
    case "FischbuchEintragLinthkanal":
        await getFischbuchEintraegeInitial(reactiveData, clientRouteName)
        return await getFischbuchEintraege(reactiveData , clientRouteName) 
   }
    

    
  }


  public async deleteDynamicGridRow(reactiveData: DynamicDataSet, clientRouteName: string, detailRowId: string) : Promise<DynamicGridRowParentChild | undefined> {
    const parentDetail = await findLocalFishingDetailRecordById("", detailRowId)
    if ( parentDetail && parentDetail.childRow && parentDetail.childRow.syncState) {

        parentDetail.childRow.cell.image.imageType = "notsynced"
        parentDetail.childRow.cell.image.imagePath = "img/icons/notsynced.svg"
        parentDetail.childRow.isSynced = false
        if ( parentDetail.childRow.syncState) {
            parentDetail.childRow.syncState.isSynched = false
            parentDetail.childRow.syncState.stateResourceId = "Sync.State.Delete"
        }


        parentDetail.parentRow.cell.content.title = await resolveMainListResourceTitel("fishBookEntriesWithDetail", parentDetail.parentRow.cell.content.titleResourceId ?? "", parentDetail.parentRow )
        parentDetail.parentRow.isSynced = false
        parentDetail.parentRow.cell.image.imagePath = "img/icons/notsynced.svg"
        parentDetail.parentRow.cell.image.imagePath = "img/icons/notsynced.svg"
        if ( parentDetail.parentRow.syncState) {
            parentDetail.parentRow.syncState.stateResourceId = "Sync.State.Change"
            parentDetail.parentRow.syncState.isSynched = false
        }
        

        await deleteFischbuchDynamicGrid(parentDetail)   
        await syncFishbook({parentRow: parentDetail.parentRow, childRow: parentDetail.childRow}, "deletedetail")   


    }
    return parentDetail
  }

  public async saveRemoteFormOverwriteFormWithBusinessCase(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<DynamicGridRowParentChild | undefined> {
    
    let isUpdate = false
    if ( clientRouteName === "FishbookDetailRecord") {
        isUpdate = true
    }

    
            

    switch( reactiveData.viewId) {
        case "HomeFischerei":
            saveHomeFischerei(reactiveData)
            return
        case "FischbuchEintragPachtrevier":
            return  await saveFischbuchEintragPachtrevier(reactiveData, isUpdate)
        case "FischbuchEintragPatentrevier":
            return await saveFischbuchEintragPatentrevier(reactiveData, isUpdate)
        case "FischbuchEintragLinthkanal":
            return  await saveFischbuchEintragLinthkanal(reactiveData, isUpdate)        
       
       }
  return
  }
}

async function getHomeFischerei(reactiveData: DynamicDataSet) : Promise<any> {   
    const receiverRuleForm: RuleLoadFishing = new RuleLoadFishing(reactiveData);
    const formResponse = await receiverRuleForm.asyncExecute()
    return formResponse
}
function saveHomeFischerei(reactiveData: DynamicDataSet) : boolean {
    return true
}


async function getFischbuchEintraege(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<boolean> {
    const fishingEditState = await FishingEdit.getEditFishingStateAsync()
    if ( fishingEditState.startDate === undefined )
        return true

    if ( clientRouteName !== "FischbuchEintragPatentrevier" 
        && clientRouteName !== "FischbuchEintragPachtrevier" 
        && clientRouteName !== "FischbuchEintragLinthkanal"
        )
        return true

        reactiveData.view.forEach(row => {
            if (row.formularFeldname === "Datum") {               
                row.istSichtbar = true
                row.value = ConvertDateToDayString(fishingEditState.startDate)
                row.dateValue =  fishingEditState.startDate
            }
            

            if (row.formularFeldname === "Patent") {               
                row.istSichtbar = true
                row.value = `${fishingEditState.patent?.value}` 
            }

            if (row.formularFeldname === "Fischereirevier") {               
                row.istSichtbar = true
                row.value = fishingEditState.fischereiRevier?.value
            }

    })
    return true
}

async function getFischbuchEintraegeInitial(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<boolean> {
    let isEditPossible = false

    let gueltigDauerInMinuten = 24*60
    if ( reactiveData.viewId === "FischbuchEintragPatentrevier") {
        gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdPatentfischerFischFangAenderung") * 60
    }
    if ( reactiveData.viewId === "FischbuchEintragPachtrevier") {
        gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdPaechterFischFangAenderung") * 60
    }
    if ( reactiveData.viewId === "FischbuchEintragLinthkanal") {
        gueltigDauerInMinuten = await  ComboBoxDataSource.GetValueAsIntByKey("SystemParameter", "MaxStdPatentfischerFischFangAenderung") * 60
    }

    if ( gueltigDauerInMinuten === 0) gueltigDauerInMinuten = 24*60

    
   
    if ( clientRouteName !== "Fishing" && clientRouteName !== "FischbuchEintragPatentrevier"
    && clientRouteName !== "FischbuchEintragPachtrevier"
    && clientRouteName !== "FischbuchEintragLinthkanal"
    ) {

        const gridRowTemp = DynamicDetailListStore.GetSelectedGridRow()
        const gridRow = gridRowTemp as unknown as DynamicGridRow

        reactiveData.view.forEach(row => {
            if ( row.formularFeldname === "Gewicht") {
                if (row.value && row.value.length > 0) {
                    row.value =  row.value.replace(",", ".")
                }
            }
            if ( row.formularFeldname === "Laenge") {
                if (row.value && row.value.length > 0) {
                    row.value =  row.value.replace(",", ".")
                }
            }

            if ( row.formularFeldname === "Dauer") {
                if (row.value && row.value.length > 0) {
                    row.value =  row.value.replace(",", ".")
                }
            }

            if ( row.formularFeldname === "DatumZeitErzeugung") {
                
                if ( ! row.value || row.value === "") {
                    const dt = newDate()
                    row.value = ConvertDateToIsoString(dt)
                    row.dateValue  = GenerateCorrectTime( new Date(makeValidUtcString(row.value)))
                }

                const dateValue = GenerateCorrectTime( ConvertUtcStringToDate(row.value) )
                const dt = newDate()

                
                let minutes = GetDateDiffMinutes(dt,dateValue)
                if ( dateValue.getTime() > dt.getTime()) minutes = 0


                if ( minutes <= gueltigDauerInMinuten) isEditPossible = true
                
            }
        })

        if (gridRow && (!gridRow.appSynchronId || gridRow.appSynchronId.length < 1 ) ) {
            isEditPossible = false
        }

        if (gridRow && ! (gridRow.fangStatistikDigital ?? false) ) {
            isEditPossible = false
        }

        if ( ! isEditPossible) {
            reactiveData.view.forEach( row => {
                if (row.formularFeldname === "RuleDynamicSave") {               
                    row.istSichtbar = false
                } 
                if (row.formularFeldname === "RuleDynamicDelete") {               
                    row.istSichtbar = false
                }  
                if (row.formularFeldname === "RuleDynamicShouldDelete") {               
                    row.istSichtbar = false
                }            
                if (row.formularFeldname === "RuleDynamicChange") {               
                    row.istSichtbar = false
                }            
                                        
                if (row.formularFeldname === "Fischart") {               
                    row.istEditierbar = false
                }
                if (row.formularFeldname === "Laenge") {               
                    row.istEditierbar = false
                }            
                if (row.formularFeldname === "Gewicht") {               
                    row.istEditierbar = false
                }            
                if (row.formularFeldname === "Anzahl") {               
                    row.istEditierbar = false
                }            
                if (row.formularFeldname === "Dauer") {               
                    row.istEditierbar = false
                }            
            })
            return true
        }
    }

    reactiveData.view.forEach(row => {
        
        if ( clientRouteName === "Fishing" || clientRouteName === "FischbuchEintragPatentrevier"
            || clientRouteName === "FischbuchEintragPachtrevier"
            || clientRouteName === "FischbuchEintragLinthkanal"
        
        ) {


            if (row.formularFeldname === "RuleDynamicSave") {               
                row.istSichtbar = true
            } 
            if (row.formularFeldname === "RuleDynamicDelete") {               
                row.istSichtbar = false
            }  
            if (row.formularFeldname === "RuleDynamicShouldDelete") {               
                row.istSichtbar = false
            }            
            if (row.formularFeldname === "RuleDynamicChange") {               
                row.istSichtbar = false
            }            
                                    
            if (row.formularFeldname === "Fischart") {               
                row.istEditierbar = true
            }
            if (row.formularFeldname === "Laenge") {               
                row.istEditierbar = true
            }            
            if (row.formularFeldname === "Gewicht") {               
                row.istEditierbar = true
            }            
            if (row.formularFeldname === "Anzahl") {               
                row.istEditierbar = true
            }            
            if (row.formularFeldname === "Dauer") {               
                row.istEditierbar = true
            }            

            return true
        }

        

        if (row.formularFeldname === "RuleDynamicSave") {               
            row.istSichtbar = false
        } 
        if (row.formularFeldname === "RuleDynamicDelete") {               
            row.istSichtbar = false
        }  
        if (row.formularFeldname === "RuleDynamicShouldDelete") {               
            row.istSichtbar = true
        }            
        if (row.formularFeldname === "RuleDynamicChange") {               
            row.istSichtbar = true
        }            
                                
        if (row.formularFeldname === "Fischart") {               
            row.istEditierbar = false
        }
        if (row.formularFeldname === "Laenge") {               
            row.istEditierbar = false
        }            
        if (row.formularFeldname === "Gewicht") {               
            row.istEditierbar = false
        }            
        if (row.formularFeldname === "Anzahl") {               
            row.istEditierbar = false
        }            
        if (row.formularFeldname === "Dauer") {               
            row.istEditierbar = false
        }      
    })
    return true
}

async function saveFischbuchEintragPachtrevier(reactiveData: DynamicDataSet, isUpdate: boolean) : Promise<DynamicGridRowParentChild | undefined> {
    const parentDetailRow = await addLocalFishingDetailRecord("fishBookEntriesWithDetail",reactiveData, isUpdate)
    if ( parentDetailRow && parentDetailRow.childRow ) {

        parentDetailRow.childRow.cell.content.title = await resolveSubListResourceTitel(parentDetailRow.childRow.cell.content.titleResourceId, parentDetailRow.childRow)
        parentDetailRow.parentRow.cell.content.title = await resolveMainListResourceTitel("fishBookEntriesWithDetail", parentDetailRow.parentRow.cell.content.titleResourceId ?? "", parentDetailRow.parentRow )
        

        parentDetailRow.parentRow.cell.image.imageType = "notsynced"
        parentDetailRow.parentRow.cell.image.imagePath = "img/icons/notsynced.svg"
        parentDetailRow.parentRow.isSynced = false
        if ( parentDetailRow.parentRow.syncState) {
            parentDetailRow.parentRow.syncState.stateResourceId = "Sync.State.Change"
            parentDetailRow.parentRow.syncState.isSynched = false
        }
        parentDetailRow.childRow.cell.image.imageType = "notsynced"
        parentDetailRow.childRow.cell.image.imagePath = "img/icons/notsynced.svg"
        parentDetailRow.childRow.isSynced = false

        if ( parentDetailRow.childRow.syncState) {
            parentDetailRow.childRow.syncState.isSynched = false
            parentDetailRow.childRow.syncState.stateResourceId = "Sync.State.Change"
        }
      
        const response = await syncFishbook(parentDetailRow, "changedetail") 
        if ( response.offline) await saveFischbuchDynamicGrid(parentDetailRow, response)    
        
        return parentDetailRow      
    }
    return
}

//__ Implementieren
async function saveFischbuchEintragPatentrevier(reactiveData: DynamicDataSet, isUpdate: boolean) : Promise<DynamicGridRowParentChild | undefined> {
    const parentDetailRow = await addLocalFishingDetailRecord("fishBookEntriesWithDetail",reactiveData, isUpdate)
    if ( parentDetailRow && parentDetailRow.childRow) {
        parentDetailRow.childRow.cell.content.title = await resolveSubListResourceTitel(parentDetailRow.childRow.cell.content.titleResourceId, parentDetailRow.childRow)
        parentDetailRow.parentRow.cell.content.title = await resolveMainListResourceTitel("fishBookEntriesWithDetail", parentDetailRow.parentRow.cell.content.titleResourceId ?? "", parentDetailRow.parentRow )
        

        parentDetailRow.parentRow.cell.image.imageType = "notsynced"
        parentDetailRow.parentRow.cell.image.imagePath = "img/icons/notsynced.svg"
        parentDetailRow.parentRow.isSynced = false
        if ( parentDetailRow.parentRow.syncState) {
            parentDetailRow.parentRow.syncState.stateResourceId = "Sync.State.Change"
            parentDetailRow.parentRow.syncState.isSynched = false
        }
        parentDetailRow.childRow.cell.image.imageType = "notsynced"
        parentDetailRow.childRow.cell.image.imagePath = "img/icons/notsynced.svg"
        parentDetailRow.childRow.isSynced = false

        if ( parentDetailRow.childRow.syncState) {
            parentDetailRow.childRow.syncState.isSynched = false
            parentDetailRow.childRow.syncState.stateResourceId = "Sync.State.Change"
        }
      

        
        const response = await syncFishbook(parentDetailRow, "changedetail")   
        if ( response.offline) await saveFischbuchDynamicGrid(parentDetailRow, response)  
        if ( response && response.childRows && response.childRows[0]) {
            if (response.childRows[0].syncState && response.childRows[0].syncState.stateResourceId && response.childRows[0].syncState.stateResourceId === "Sync.State.Failed") {
                const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
                notficationState.resetNotificationState()
                notficationState.changeNotificationState({
                   isNotification: true, text: await Resource.getResourceText("Sync.State.Failed.Content"), title: await Resource.getResourceText("Sync.State.Failed.Title")})
            }
        }
        
        return parentDetailRow
    }
    return
}

async function saveFischbuchEintragLinthkanal(reactiveData: DynamicDataSet, isUpdate: boolean) : Promise<DynamicGridRowParentChild | undefined> {
    const parentDetailRow = await addLocalFishingDetailRecord("fishBookEntriesWithDetail",reactiveData, isUpdate)
    if ( parentDetailRow && parentDetailRow.childRow) {
        parentDetailRow.childRow.cell.content.title = await resolveSubListResourceTitel(parentDetailRow.childRow.cell.content.titleResourceId, parentDetailRow.childRow)
        parentDetailRow.parentRow.cell.content.title = await resolveMainListResourceTitel("fishBookEntriesWithDetail", parentDetailRow.parentRow.cell.content.titleResourceId ?? "", parentDetailRow.parentRow )
        

        parentDetailRow.parentRow.cell.image.imageType = "notsynced"
        parentDetailRow.parentRow.cell.image.imagePath = "img/icons/notsynced.svg"
        parentDetailRow.parentRow.isSynced = false
        if ( parentDetailRow.parentRow.syncState) {
            parentDetailRow.parentRow.syncState.stateResourceId = "Sync.State.Change"
            parentDetailRow.parentRow.syncState.isSynched = false
        }
        parentDetailRow.childRow.cell.image.imageType = "notsynced"
        parentDetailRow.childRow.cell.image.imagePath = "img/icons/notsynced.svg"
        parentDetailRow.childRow.isSynced = false

        if ( parentDetailRow.childRow.syncState) {
            parentDetailRow.childRow.syncState.isSynched = false
            parentDetailRow.childRow.syncState.stateResourceId = "Sync.State.Change"
        }
      

        
        const response = await syncFishbook(parentDetailRow, "changedetail") 
        if ( response.offline) await saveFischbuchDynamicGrid(parentDetailRow, response)    
        return parentDetailRow
    }   
    return
}

async function deleteFischbuchDynamicGrid(parentChild: DynamicGridRowParentChild) {
    const dataRows = await loadAllRecords(fishbookTable) as any
    if ( dataRows === undefined ) return undefined
    for(const item of dataRows) {
        const parentRow = item.row as DynamicGridRow
        if ( parentRow.id === parentChild.parentRow.id && parentRow.rows ) {
            await addOrReplaceRecord(fishbookTable, {id: parentChild.parentRow.id, row: parentChild.parentRow})
        }
       
    }
}

async function saveFischbuchDynamicGrid(parentChild: DynamicGridRowParentChild, syncState: SyncStateResponse) {
    const dataRows = await loadAllRecords(fishbookTable) as any
    if ( dataRows === undefined ) return undefined
    for(const item of dataRows) {
        const parentRow = item.row as DynamicGridRow
        if ( parentRow.id === parentChild.parentRow.id && parentRow.rows ) {
            await addOrReplaceRecord(fishbookTable, {id: parentChild.parentRow.id, row: parentChild.parentRow})
        }
       
    }
}


//"fishBookEntriesWithDetail"
async function addLocalFishingDetailRecord(viewId: string, reactiveData: DynamicDataSet, isUpdate: boolean) : Promise<DynamicGridRowParentChild | undefined> {
    if ( isUpdate) {
        const parentDetail = await findLocalFishingDetailRecord(viewId, reactiveData)
        const row = parentDetail?.childRow
        if (! row )  return undefined
        if ( row?.detailForm && row.detailForm.view && row.detailForm.view.length) {
            for( const targetRow of row.detailForm.view) {
                for( const sourceRow of reactiveData.view) {
                    if ( targetRow.formularFeldname === sourceRow.formularFeldname) {
                        targetRow.value = sourceRow.value
                    }
                }
            }
            await addOrReplaceRecord(fishbookTable, {id: parentDetail.parentRow.id, row: parentDetail.parentRow})
        }
        return {parentRow: parentDetail?.parentRow, childRow: row}
    } else {
            const row = await findLocalFishingParentRecord(viewId)
            if ( row) {
            
            const detail: FishingRecordDetail = new FishingRecordDetail()
            const detailRow = await detail.CreateFischereiFangDetailRow(row.id!)
            if ( detailRow.detailForm) {
            for( const targetRow of detailRow.detailForm?.view ) {
                for( const sourceRow of reactiveData.view) {
                    if ( targetRow.formularFeldname === sourceRow.formularFeldname) {
                        targetRow.value = sourceRow.value
                    }
                }
            }
            if ( !row.rows) {
                row.rows = []
            }
            row.rows?.push(detailRow)
            await addOrReplaceRecord(fishbookTable, {id: row.id, row})
            return {parentRow: row, childRow: detailRow}
            }
        }
    }
}