
import { onMounted, onUnmounted, reactive, ref, computed , watch} from "vue";
import { useStore } from "vuex";
import  router  from "@/router/index";

import actions from "./components/Shared/IconNavigationBar.vue";
import popup from "./components/Shared/Popup.vue"

import { Resource, ResourceText } from "@/infrastructure/resource/resource";

//import updateApp from "@/hooks/UpdateApp";
import startAppState from "@/hooks/StartAppState";

import { openDialog } from './infrastructure/observables/ModalDialogNotification';

import { serviceWorkerUpdate } from "@/registerServiceWorker";


import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import { StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
import { StateLogin } from "@/business/application/statemachine/concrete/StateLogin";
import { StateWebShop } from "@/business/application/statemachine/concrete/StateWebShop";
import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
import {useRoute} from 'vue-router'
import { UserProfile } from "./infrastructure/backend/userProfile";
import { ErrorStateServiceForRoot } from "./infrastructure/observables/errorState";
import { LoaderStateServiceForRoot } from "./infrastructure/observables/loaderState";
import { MessageBusServiceForRoot } from "./infrastructure/observables/messageBus";
import { FormValidation } from "./infrastructure/frontent/validation";
import { Out } from "./infrastructure/frontent/clientMessage";
import ErrorPopup from "@/components/Shared/ErrorPopup.vue";
import LoaderPopup from "@/components/Shared/LoaderPopup.vue";
import { AsyncRestClient } from "./infrastructure/backend/async/AsyncRestClient";
import { ComboBoxDataSource } from "./components/Shared/Dynamicform/ComboBoxDataSource";
import { NotificationStateServiceForRoot } from "./infrastructure/observables/notficationState";
import NotificationPopup from "@/components/Shared/Notification.vue"
import { DynamicListToDetailFormChannelForRoot } from "@/components/Shared/dynamicList/Hooks/DynamicListToDetailFormChannel";
import { DynamicDetailToFormChannelForRoot } from "@/components/Shared/dynamicList/Hooks/DynamicDetailFormToListChannel";
import { RouterGuard } from "@/hooks/RouterGuard"
import { RouterUiControl} from "@/hooks/RouterUIControl"
import { ClearFormStore } from "@/hooks/ClearFormStore"
import RoutedNavigationBar from "@/components/Shared/routed-button-bar/RoutedNavigationBar.vue"
import { RoutedButtonBarRequestChannelForRoot, RoutedButtonBarRequestState } from "@/components/Shared/routed-button-bar/RoutedButtonBarRequestChannel"
import { RoutedButtonBarCleanupChannelForRoot, RoutedButtonBarCleanupState } from "./components/Shared/routed-button-bar/RoutedButtonBarCleanupChannel";
import { Identity } from "@/infrastructure/backend/userIdentity"
import { LogoutChannelForRoot } from "./infrastructure/observables/logoutChannel";
import { OfflineForms } from "./components/Shared/Dynamicform/DynamicFormPreload";
import { FishingEdit } from './components/fishing/business/FishingEdit';
import { RouterOfflineGuard} from "@/hooks/RouterOfflineGuard"
import { RouterChangedNotification } from "./hooks/RouterChangeNotification";
import { reSyncFishbook, reSyncFishbookApi } from "./infrastructure/serviceworker-related/ReSyncFishBook";
import { getCookie, removeCookie } from "typescript-cookie";
import { isAllSynced } from "./infrastructure/serviceworker-related/Sync";
import { deleteUserContent } from "./infrastructure/database/asyncDb";
import { Gps } from "@/components/Shared/geo/gps";
import { OnOfflineListener } from "./hooks/OnOfllineState";
import { reSyncWildBookApi} from "@/infrastructure/serviceworker-related/ReSyncWildBook"
import { DynamicNavigation } from "./components/Shared/dynamicNavigation/DynamicNavigation";
import { UnmountDynamicForm } from "./infrastructure/observables/UnmountDynamicForm";
import { LocalWarenkorb } from "./components/webshop/LocalWarenkorb";
import { OnlineInfo } from "./infrastructure/serviceworker-related/OnlineCheck";
import { AppState, WebShopStartedFrom } from "./AppState";
import { StateProduct } from "./business/application/statemachine/concrete/StateProduct";
import { DynamicLoadFromListData } from "./components/Shared/Dynamicform/DynamicLoadFromListData";
import { UpdateAppVersion } from "./infrastructure/serviceworker-related/UpdateAppVersion";
import { RemoteProdukt } from "./components/webshop/RemoteProdukt";
import { RemoteWarenKorb } from "./components/webshop/RemoteWarenKorb";
import { Navigate } from "@/infrastructure/backend/async/Navigate";
import { InitialStartRequest} from "@/infrastructure/backend/async/InitialStartRequest"
import { RefreshLoginWhenResourceLoaded } from "@/infrastructure/frontent/RefreshLoginWhenResourceLoaded";
import { RefreshAppLanguage } from "@/infrastructure/backend/RefreshAppLanguage";
import  WebshopPaymentNavBar from "@/components/webshop/WebshopPaymentNavBar.vue"

// ------------------------------------
// --- Manuellen Update Dialog anzeigen
// if ( isServiceWorkerWaiting()) {
//   openUpdate()
// }
// ------------------------------------


export default {
  name: "UserLogin",
  inheritAttrs: false,
  components: {
    actions,
    popup,
    ErrorPopup, 
    LoaderPopup, 
    NotificationPopup,
    RoutedNavigationBar,
    WebshopPaymentNavBar
  },
  setup() {
    let unsubscribe: any = {};
    const store = useStore();
    const showHeaderSection = ref(true);
    const waterMarkImage = ref("img/background/Background.svg");
    const modalDialogName = ref("");
    const showRoutedBar = ref<boolean>(false)
    const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
    const kanton = ref<string>("")
    let expired = false

    Identity.isUserRole()

   
    DynamicNavigation.create()

    setInterval(() => {
        //loaderState.changeLoaderState({isInProgress: true})
        console.error("Timeout")
        //deletePaymentWrapper()
        //router.push({ name: 'WebshopFailed'});
        //loaderState.changeLoaderState({isInProgress: false})
      }, 20000);
    

    const online = ref(true);
    const route = useRoute();

    
    const clientRouteName = computed(() =>route.name?.toString())

    const isSynced = ref(true)

    let offlineTextTitle = "Die App ist offline"
    let offlineTextContent = "Diese Aktion ist im offline Mode nicht möglich."

    const refreshService = RefreshAppLanguage.getRefreshAppService()
    const onRefreshService = refreshService.subscription().subscribe({
      next: (value) => {
        if ( value.refresh ) {
          refreshAfterLanguageChanged()
        }
      }
    })

    async function refreshAfterLanguageChanged() {
      kanton.value = ( await Resource.getResourceText("App.Kanton.Title") ) ?? ""
    }

    const onOfflineListener =  OnOfflineListener.getService()
    const onOfflineState = onOfflineListener.subscription().subscribe({
      next: (value) => {
        if ( value === undefined) return
        if ( value.IsOnline) {
          online.value = true;
          return
        }
        online.value = false
        webShopBezahlenOffline()
       

      }
    })

    async function webShopBezahlenOffline() {
      try {
          //if ( clientRouteName.value && clientRouteName.value === "WebShopKasse") {
            // stateMachine = StateMachineServiceLocator.get();
            // stateMachine.setStartState(new StateLogin());
            // stateMachine.next(
            //     {
            //       navigation: StateNavigationAction.Jump,
            //       conditions: [{ nameId: "*", value: "Home" }]
            //     },
            //     { lookahead: false }
            //   );
            //   changeNavigation(store);
          //}

        if ( clientRouteName.value && clientRouteName.value === "WebShopKasse") {
          const stateMachine = StateMachineServiceLocator.get();
          stateMachine.setAppUpdateStateDetailed( new StateWebShop(), {
          oldMainState: "WebShop", 
          oldState: "WebShopWarenKorb", 
          newMainState: "WebShop", 
          newState: "WebShopKasseFailed", 
          routeName: "WebShopKasse",   
          })

          let currentState = stateMachine.getCurrentResultState();
          stateMachine.next( { navigation: StateNavigationAction.Next, conditions: []}, { lookahead: false,});
          changeNavigation(store);
        }

        } catch(e) {
         console.error(e)
        }
    }

    let stateMachine: any = undefined
     
    

   
   

   

    async function start( caller: string) {
      const startClient = AsyncRestClient.Create(true);
        if (startClient.isFailure) return;

        isSynced.value = await isAllSynced()
        // const startResult = await startClient
        //     .getValue()
        //     .exchangeForComponents<string, string>(process.env.VUE_APP_API + startUri, JSON.stringify({appName: "eFJ"}));  
        // if (startResult.isFailure) return;
        // try {
        //   const temp = startResult.getValue()
        //   if (temp && (temp as any).offline) return 
        // } 
        // catch(e) {
        //   return
        // }

        const startResult = await InitialStartRequest.Start( process.env.VUE_APP_API + "api/authentication/start" , caller )
        if( !startResult ) return

        await ComboBoxDataSource.initNamedLists(false)

        offlineTextTitle = await Resource.getResourceText(`Offline.Message.Title`)
        offlineTextContent = await Resource.getResourceText(`Offline.Message.Content`)

    }

    async function loadResource() {
      loaderState.changeLoaderState({isInProgress: true})
      
      const userProfile: UserProfile = new UserProfile()
      const profile = await userProfile.GetProfileData()
      online.value = profile.online
      

      if (!Identity.isUserRole()) {
        await start("IsUserInRole")
        await Resource.loadResources("App");
        await Resource.loadSyncWrapper()
        kanton.value = ( await Resource.getResourceText("App.Kanton.Title") ) ?? ""
      }
     
      
       
      offlineTextTitle = await Resource.getResourceText(`Offline.Message.Title`)
      offlineTextContent = await Resource.getResourceText(`Offline.Message.Content`)

     
      

      
      await Resource.loadSyncWrapper()
      stateMachine = StateMachineServiceLocator.get();
      stateMachine.setStartState(new StateLogin());
      await FishingEdit.loadFishingEditSate()
      stateMachine.getCurrentResultState();
      
      if (! await jumpToViewWhenUserLoggedIn()) {
        await start("!jumpToViewWhenUserLoggedIn")
        stateMachine.next({navigation: StateNavigationAction.Jump,conditions: [{ nameId: "*", value: "Login" }],},{ lookahead: false });
        changeNavigation(store);

      } 

     
     
      loaderState.changeLoaderState({isInProgress: false})
      
    }

    const logoutState = LogoutChannelForRoot.getService()
    const onLogoutState = logoutState.subscription().subscribe({
      next: (value) => {
        if ( value.cleanUp) {
          navigateToLogin(value.login)
        }
      }
    })

   
    
    async function navigateToLogin(isFromLogin: boolean) {
      expired = true
      Navigate.Reset()

      isSynced.value = await isAllSynced()
      if ( !isSynced.value && !isFromLogin) return

      const profile: UserProfile  = new UserProfile()
      if ( isSynced.value) {
        await profile.reset("App")
      } else {
        await profile.setUnsyncedData()
      }
     
      const navigation = StateNavigationAction.Next;
      const conditions = [];
      conditions.push({ nameId: "Login", value: "Login" });
      stateMachine.setStartState(new StateLogin())
      stateMachine.next(
        { navigation: navigation,conditions: conditions},
        { lookahead: false}
      )

      changeNavigation(store);  

      logoutState.changeLogoutState({cleanUp: false, login: false})   
      try {
        removeCookie("X-UserClaim-Names", { path: '/' })
        removeCookie("X-Access-Token", { path: '/'})
        removeCookie("XSRF-TOKEN", { path: '/' })
        await start("NavigateToLogin")
      } catch(e) {
       Out.noOperation("")
      }   
    }


    // const routerNavigationService =  RouterChangedNotification.getService()
    // const onRouterNavigation = routerNavigationService.subscription().subscribe({
    //   next: (value) => {
    //     if ( value.routeName && ( value.routeName === "FishBook" || value.routeName === "Hunting" || value.routeName === "Home") ) {
    //       reSync();
    //     }
    //    }
    // })


    
    // async function reSync() {
    //   isSynced.value = await isAllSynced()
    //   await reSyncFishbookApi()
    //   await reSyncWildBookApi()
    // }

    const errorState = ErrorStateServiceForRoot.getErrorStateService()
    const errorDialogParameter = reactive({
      message: "",
      errorTitleResourceId: "",
      errorContentResourceId: "",      
      isError: false,
      dialogName: "",
      })

      const errorStateSubscription = errorState.subscription().subscribe({
      next: async (value) => {
        if ( value.errorTitleResourceId === undefined || value.errorTitleResourceId === ""  ) {
          return
        }

        if (value.message && value.message.length > 0 && value.message.indexOf("api") >= 0) {
          errorDialogParameter.message = value.message.replace("api","");  
          errorDialogParameter.isError = value.isError;      
        
          errorDialogParameter.errorContentResourceId = value.errorContentResourceId;
          errorDialogParameter.errorTitleResourceId = value.errorTitleResourceId;
          return
        }

        errorDialogParameter.message = value.message;  
        errorDialogParameter.isError = value.isError;      
        
        errorDialogParameter.errorContentResourceId = await Resource.getResourceText(value.errorContentResourceId);
        errorDialogParameter.errorTitleResourceId = await Resource.getResourceText(value.errorTitleResourceId);
        
      }
    })

    

    const loaderParameter = reactive({
      isInProgress: false
    })

    const loaderStateSubscription = loaderState.subscription().subscribe({
      next: (value) => {
        loaderParameter.isInProgress = value.isInProgress;   
      }
    })

    const notificationState = NotificationStateServiceForRoot.getNotificationStateService()
    const notificationParameter = reactive({
      isNotification: false,   
      title: "",
      text: ""
      })

      const notificationStateSubscription = notificationState.subscription().subscribe({
        next: (value) => {          
          if (! errorDialogParameter.isError)
          notificationParameter.isNotification = value.isNotification
          notificationParameter.text = value.text
          notificationParameter.title = value.title
          
        }
      })


    const messageBus = MessageBusServiceForRoot.getMessageStateService();
    const messageStateSubscription = messageBus.subscription().subscribe({
      next: (value) => {
        if ( value.source === "login" &&  value.topic ==="loginSuccess") {           
          const validationRules =  new  FormValidation() 
          if ( !validationRules ) {
            Out.error("App.ValidationRules.Error => No ValidationRules")
          }

        } 
      }
    })
    function errorClosed() {
      errorDialogParameter.isError = false;
    }
    function notificationClosed() {
      notificationParameter.isNotification = false;
    }

    const routedButtonBarRequest = RoutedButtonBarRequestChannelForRoot.getService();
    const routedButtonBarCleanup = RoutedButtonBarCleanupChannelForRoot.getService();


    const onRoutedButtonBarRequest = routedButtonBarRequest.subscription().subscribe({
      next: (value) => {
        if (value.targetRoutes && (value.targetRoutes.vorwardTargetRoute || value.targetRoutes.backTargetRoute )) {
          showRoutedBar.value = true
        }
      }
    })
    const onRoutedButtonBarCleanup = routedButtonBarCleanup.subscription().subscribe({
      next: (value) => {
        showRoutedBar.value = false
      }
    })


    
    loadResource();

    //const app = startAppState(store)
    const app = startAppState(store)

    function getRole() {
      try {
      const identity = Identity.getIdentityViaCookie()
      return identity.getValue().Roles
      } catch(e) {
        Out.noOperation()
      }
      return "Anonymous"
    }

    function checkCookies() {
      try {
      const identity = Identity.getIdentity()
      return identity.getValue().Roles
      } catch(e) {
        Out.noOperation()
      }
      return "Anonymous"
    }

    

    function modalDialogClosed(modalDialogName: string) {
      if (modalDialogName === "updateApp") {
        enrollNewVersion()
      }
    }

    async function enrollNewVersion() {
      const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
      loaderState.changeLoaderState({isInProgress: true})

      offlineTextTitle = await Resource.getResourceText(`Offline.Message.Title`)
      offlineTextContent = await Resource.getResourceText(`Offline.Message.Content`)
      await UpdateAppVersion.updateToNewVersion()
      offlineTextTitle = await Resource.getResourceText(`Offline.Message.Title`)
      offlineTextContent = await Resource.getResourceText(`Offline.Message.Content`)
      app.refreshApp()
     
    }

    async function setOnlineToStore( isOnline: boolean ) {
      const userProfile: UserProfile = new UserProfile()
       await userProfile.ChangeOnline(isOnline)
    }



    async function jumpToViewWhenUserLoggedIn() : Promise<boolean> {
      const userProfile: UserProfile = new UserProfile()
      
      if ( await userProfile.IsLoggedOn() ) {
        if (route.name === "Login") {  
              loaderState.changeLoaderState({isInProgress: true})   
              
              try { 
                offlineTextTitle = await Resource.getResourceText(`Offline.Message.Title`)
                offlineTextContent = await Resource.getResourceText(`Offline.Message.Content`)
                kanton.value = ( await Resource.getResourceText("App.Kanton.Title") ) ?? ""
              } catch(e) {
                Out.noOperation("")
              }

              await handleOfflineStateWhenJumpToHome()
              
              stateMachine.next(
                {
                  navigation: StateNavigationAction.Jump,
                  conditions: [{ nameId: "*", value: "Home" }]
                },
                { lookahead: false }
              );
              changeNavigation(store);
            }
            return true;
      }
      try {
        isSynced.value = await isAllSynced()
        if ( isSynced.value) {
          removeCookie("X-UserClaim-Names", { path: '/' })
          removeCookie("X-Access-Token", { path: '/' })
          removeCookie("XSRF-TOKEN", { path: '/' })
        }
        
      } catch(e) {
       Out.noOperation("")
      }
  
      return false
    }

    async function handleOfflineStateWhenJumpToHome() {
      await setOnlineToStore(online.value);
      onOfflineListener.onlineChanged({IsOnline: online.value})
    }

    // async function CheckOnlineStateAtStartUp() {
    //   const result = await OnlineInfo.isOnline()
    //   if (!result) {
    //     online.value = false;
    //     await setOnlineToStore(online.value);
    //     onOfflineListener.onlineChanged({IsOnline: online.value})
    //   }
    // }

    // async function CheckOnlineStateWhenOffline() {
    //   const result = await OnlineInfo.isOnline()
    //   if (result) {
    //     online.value = true;
    //     await setOnlineToStore(online.value);
    //     onOfflineListener.onlineChanged({IsOnline: online.value})
    //   }
    // }

    watch( () => online.value, (first: any, second: any) => {
          if ( !first ) {
            setOffline()
          }
        }
      );

    async function setOffline() {
      await setOnlineToStore(online.value);
      onOfflineListener.onlineChanged({IsOnline: online.value})
    }


    window.addEventListener("offline", async () => {
      online.value = false;
    });
    window.addEventListener("online", async () => {
      online.value = true;
      await setOnlineToStore(online.value);
      onOfflineListener.onlineChanged({IsOnline: online.value})
    });

    window.addEventListener("beforeunload", async () => {
      console.error("onbeforeunload")
    });

    const unmountDynamicForm = UnmountDynamicForm.getService()
    router.beforeEach((to, from) => {
      if ( !from ) return true
      if ( ! from.name ) return true


      if (to && to.name && (from.name === "WebShopDaten" && to.name === "WebShopFilter")) {
        DynamicLoadFromListData.reset()
      }

      if (to && to.name && (from.name === "WildBookFilter" && to.name === "Hunting")) {
        DynamicLoadFromListData.reset()
      }

      if (to && to.name && (from.name === "Hunting" && to.name === "Products")) {
        startWebShopFrom("Hunting")
        
      }
      if (to && to.name && (from.name === "Fishing" && to.name === "Products")) {
        startWebShopFrom("Fishing")
      }
      
      if (to && to.name && (from.name === "WebShopFilter" && to.name === "Products")) {
        refereshSynchResources()
      }
      

      if ( from.name === "WildBookEntryLocation") {
        unmountDynamicForm.unMount({unMount: true})
        unmountDynamicForm.reset()

      }
      if ( from.name === "WildBookEntry") {
        unmountDynamicForm.unMount({unMount: true})
        unmountDynamicForm.reset()
      }

     
      return true
    })

    async function startWebShopFrom(from: WebShopStartedFrom) {
      await RemoteWarenKorb.cleanUpWebShopProducts()
      await LocalWarenkorb.Clear()
      await AppState.saveWarenkorbStart(from)
    }

    async function refereshSynchResources() {
      await RemoteWarenKorb.cleanUpWebShopProducts()
      await LocalWarenkorb.Clear()
      await Resource.loadSyncWrapper()
    }


    router.afterEach((to, from) => {
      waterMarkImage.value = "none";
      showHeaderSection.value = true;
      if (to.name === "Login") {
        showHeaderSection.value = false;
        waterMarkImage.value = "img/background/Background.svg";
      }
      if (app.isRefresh()) {
        window.location.reload();
        const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        loaderState.changeLoaderState({isInProgress: false})
      }

      if ( to.name === "Login" || to.name === "Home" || to.name === "Hunting" || to.name === "Fishing" ) {
        app.setLastViewState(store, to.name);
      }
      serviceWorkerUpdate();
      
    });

    

    router.beforeEach((to, from) => {
      RouterUiControl.ControlWaitForResponse(router.getRoutes(), from.name, to.name, store)

      

      if ( online.value ) {
        if ( !expired && Identity.isUserRole() && (!isSynced.value || FishingEdit.getEditFishingStateInfo(true).isStarted)) {
            let alreadyDone = false
            if ( RouterOfflineGuard && to && to.name) {
              if (RouterOfflineGuard.RouteTo === to.name?.toString()) alreadyDone = true
            }

            if ( to && to.name && from && from.name && to.name === "Login" ) {
            if ( alreadyDone ) {
              return false
            }
         

 
            //  const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            //  notficationState.resetNotificationState()
            //  notficationState.changeNotificationState({
            //     isNotification: true, text:  Resource.getResourceTextSyncMode("Logout.NotSynced.Content", ""), title: Resource.getResourceTextSyncMode("Logout.NotSynced.Title" , "")})
            


            if ( to && to.name && from && from.name && to.name === "Login" ) {
              stateMachine.next({navigation: StateNavigationAction.Jump,conditions: [{ nameId: "*", value: "Home" }] },{ lookahead: false });
              changeNavigation(store);
              RouterOfflineGuard.RouteTo = undefined
              return false
            } 

            stateMachine.last()
            changeNavigation(store)       

            return false
          }
        }

        //expired = false
      }

      if ( to && to.name && from && from.name && to.name === "Home" && from.name === "Login" ) {
        expired = false
      }

      if (!online.value) {
        let alreadyDone = false
        if ( RouterOfflineGuard && to && to.name) {
          if (RouterOfflineGuard.RouteTo === to.name?.toString()) alreadyDone = true
        }

       
        if ( !RouterOfflineGuard.IsAllowed(from.name, to.name) ) {
         
         if ( alreadyDone ) {
          return false
         }

        
         
         const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
         notficationState.resetNotificationState()
         if (from.name && from.name === "Login" && to.name && to.name === "Home") {
          
            notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.Information.Content", ""), title: Resource.getResourceTextSyncMode("Offline.Information.Title", "")})

         }        

         if ( to && to.name && from && from.name && to.name === "Login" && Identity.isUserRole() ) {
           stateMachine.next(
            { navigation: StateNavigationAction.Jump,
              conditions: [{ nameId: "*", value: "Home" }]
            },
            { lookahead: false }
           );
          changeNavigation(store);
          RouterOfflineGuard.RouteTo = undefined
          return false
         } 

        if ( from && to && from.name && to.name && 
        from.name !== "WebShopFilter" && to.name !== "WebShopDaten" 
        ) {
          stateMachine.last()
          changeNavigation(store)   
        }
        
        
        notficationState.changeNotificationState({isNotification: true, text:  offlineTextContent, title: offlineTextTitle})
        RouterOfflineGuard.RouteTo = undefined


        return false
        }
      }        
        
        
      if ( ! RouterGuard.IsAllowed(router.getRoutes(), from.name, to.name, store, stateMachine, isSynced.value) ) {
        return false
      }
        


      if ( ! ClearFormStore.ShouldClear(from.name, to.name, store) )
        return false

     

      if (to.name === "Login") {
        if (online.value === false && from.name && Identity.isUserRole()) {
         openDialog(store, {name: "Offline Mode", isOk: true, isCancel: false, titel: offlineTextTitle, text: offlineTextContent, open: true, type: "info"})
         stateMachine.next(
            {
              navigation: StateNavigationAction.Jump,
              conditions: [{ nameId: "*", value: "Home" }]
            },
            { lookahead: false }
          );
          changeNavigation(store);
        } else {
          return true
        }
      } else {
         return true
      }



    });

   
    // function  getRandomString () {
    //   return Math.random().toString(36).substring(2, 15)
    // }

    // function isOnlineOnStartUp () {
    //   if ( ! window || !window.navigator) return true
    //   if (!window.navigator.onLine) return false
    //   return true
    // }

    onMounted(() => {
      

      unsubscribe = store.subscribe((value) => {
        if (value.type === "modalddialogstore/openDialog") {
          modalDialogName.value = "efj__backdrop";
        }
        if (value.type === "modalddialogstore/closeDialog") {
          modalDialogName.value = "";
        }
      });
    });

    onUnmounted(() => {
      DynamicNavigation.destroy()
      onLogoutState.unsubscribe();
      errorStateSubscription.unsubscribe();
      loaderStateSubscription.unsubscribe();
      messageStateSubscription.unsubscribe();
      notificationStateSubscription.unsubscribe();
      onRoutedButtonBarRequest.unsubscribe();
      onRoutedButtonBarCleanup.unsubscribe();
      onOfflineState.unsubscribe();
      onRefreshService.unsubscribe();
      unsubscribe();
    });

    return {
      errorDialogParameter,
      loaderParameter,
      notificationParameter,
      errorClosed,
      notificationClosed,
      showHeaderSection,
      waterMarkImage,
      modalDialogName,      
      modalDialogClosed,
      getRole,
      online,
      showRoutedBar,
      kanton
    };
  }
};
